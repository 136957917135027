var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('create-ui',{attrs:{"loading":_vm.loading,"title":"Ajout d'un traducteur"},on:{"close":function($event){return _vm.$emit('close')},"create":_vm.create},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Numéro de série","rules":[
            (v) =>
              !!v || 'Ce champ est requis pour ajouter un nouveau traducteur',
            (v) =>
              (!!v && !_vm.isAllowed(v)) ||
              'Ce numéro de série est déjà existant',
          ]},model:{value:(_vm.serialNumber),callback:function ($$v) {_vm.serialNumber=$$v},expression:"serialNumber"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Numéro de PV","rules":[
            (v) =>
              !!v || 'Ce champ est requis pour ajouter un nouveau traducteur',
          ]},model:{value:(_vm.pvNumber),callback:function ($$v) {_vm.pvNumber=$$v},expression:"pvNumber"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Type","rules":[
            (v) =>
              !!v || 'Ce champ est requis pour ajouter un nouveau traducteur',
          ]},model:{value:(_vm.equipmentType),callback:function ($$v) {_vm.equipmentType=$$v},expression:"equipmentType"}})],1),_c('v-col',[_c('equipment-brand-autocomplete',{model:{value:(_vm.equipmentBrandId),callback:function ($$v) {_vm.equipmentBrandId=$$v},expression:"equipmentBrandId"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":"Diamètre","suffix":"mm","rules":[
            (v) => !!v || v === 0 || 'Ce champ est requis',
            (v) => v >= 0 || 'La valeur doit être positive',
          ]},model:{value:(_vm.diameter),callback:function ($$v) {_vm.diameter=_vm._n($$v)},expression:"diameter"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":"Fréquence","suffix":"MHz","rules":[
            (v) => !!v || v === 0 || 'Ce champ est requis',
            (v) => v >= 0 || 'La valeur doit être positive',
          ]},model:{value:(_vm.frequency),callback:function ($$v) {_vm.frequency=_vm._n($$v)},expression:"frequency"}})],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }